<template>
  <BaseAlertTemplate
    :detailedData="detailedData"
    :settingsData="settingsData"
    :algoStrategyData="algoStrategyData"
    :alert="alert"
  >
  </BaseAlertTemplate>
</template>

<script>
import BaseAlertTemplate from './Base/BaseAlertTemplate.vue'
export default {
  name: 'ValidatedIOIncoherentCheckPremep',
  props: ['alert'],
  components: {
    BaseAlertTemplate
  },
  data: function () {
    return {
      detailedData: [
        {
          label: 'Error messages',
          value: (alert) => {
            const errorsMessage = alert?.data?.detailed_data?.error_messages
            if (!errorsMessage) {
              return ''
            }
            const errorDetails = errorsMessage?.error_details

            if (!errorDetails) {
              return ''
            }
            return errorDetails.map((error) => {
              const errorMessage = error?.error_message
              return errorMessage
                ? this.$commonUtils.htmlEntities(errorMessage)
                : errorsMessage
            }).join('<br>')
          },
          endLabel: '',
          roundValue: false,
          isPerc: false,
          colorize: false
        }
      ],
      settingsData: [
      ],
      algoStrategyData: [
      ]
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  }
}
</script>

<style>
</style>

<style scoped>

</style>
